import { getPlaystoreID } from '@/common/config';
import { uuidFetcher } from '@/fetcher/uuid';
import AppStore from '@/public/images/app_store.svg';
import AppStoreEN from '@/public/images/apple_en.svg';
import Google from '@/public/images/google.svg';
import GoogleEN from '@/public/images/google_en.svg';
import OnboardingImageOne from '@/public/images/onboarding_1.webp';
import OnboardingImageTwo from '@/public/images/onboarding_2.webp';
import OnboardingImageFour from '@/public/images/onboarding_4.webp';
import OnboardingImageFive from '@/public/images/onboarding_5.webp';
import { fLogEvent } from '@/utils/firebase';
import { regexFormatter } from '@/utils/formatter';
import { Box, Stack } from '@mui/material';
import { GetStaticPropsContext } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import useSWRImmutable from 'swr/immutable';
import i18nConfig from '../next-i18next.config';

type ComponentName = 1 | 2 | 3 | 4 | 5;
interface ComponentProps {
  step: ComponentName[];
  setStep: (step: ComponentName[]) => void;
}

const FirstBlock: FC<ComponentProps> = ({ step, setStep }) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        paddingTop: '32px',
        paddingBottom: '32px',
        whiteSpace: 'pre-line',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          fontSize: '24px',
          fontWeight: '400',
          color: '#343D54',
          paddingBottom: '4px',
        }}
      >
        {t('main.title_1')}
      </Box>
    </Stack>
  );
};

const SecondBlock: FC<ComponentProps> = ({ step, setStep }) => {
  const [ref, inView] = useInView({ threshold: 0.9 });

  useEffect(() => {
    if (inView) {
      setStep([...step, 2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Image
      ref={ref}
      priority
      src={OnboardingImageOne.src}
      alt=""
      style={{
        width: '100vw',
        height: '100%',
        maxWidth: '400px',
        marginBottom: '32px',
      }}
      width={0}
      height={0}
    />
  );
};

const ThirdBlock: FC<ComponentProps> = ({ step, setStep }) => {
  const [ref, inView] = useInView({ threshold: 0.9 });
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      setStep([...step, 3]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Stack ref={ref} sx={{ alignItems: 'center' }}>
      <Image
        src={OnboardingImageTwo.src}
        alt=""
        style={{
          width: '90vw',
          height: '100%',
          maxWidth: '360px',
          marginBottom: '32px',
        }}
        width={0}
        height={0}
      />
      <Stack
        spacing={2}
        sx={{
          paddingBottom: '64px',
          whiteSpace: 'pre-line',
        }}
      >
        <Box
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            color: '#343D54',
          }}
        >
          {t('main.title_2')}
        </Box>
        <Box sx={{ color: '#666b85', fontWeight: '400', lineHeight: '24px' }}>
          {t('main.subtitle_2')}
        </Box>
      </Stack>
    </Stack>
  );
};

const FourthBlock: FC<ComponentProps> = ({ step, setStep }) => {
  const [ref, inView] = useInView({ threshold: 0.9 });
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      setStep([...step, 4]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Stack ref={ref} sx={{ alignItems: 'center' }}>
      <Image
        src={OnboardingImageFour.src}
        alt=""
        style={{
          width: '100vw',
          height: '100%',
          maxWidth: '400px',
          marginBottom: '32px',
        }}
        width={0}
        height={0}
      />
      <Stack
        spacing={2}
        sx={{
          paddingBottom: '64px',
          whiteSpace: 'pre-line',
        }}
      >
        <Box
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            color: '#343D54',
          }}
        >
          {regexFormatter(t('main.title_3')).map((e, idx) => (
            <Box key={idx} component="span">
              {e}
            </Box>
          ))}
        </Box>
        <Box sx={{ color: '#666b85', fontWeight: '400', lineHeight: '24px' }}>
          {t('main.subtitle_3')}
        </Box>
      </Stack>
    </Stack>
  );
};

const LastBlock: FC<ComponentProps> = ({ step, setStep }) => {
  const [ref, inView] = useInView({ threshold: 0.9 });
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      setStep([...step, 5]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Stack ref={ref} sx={{ alignItems: 'center' }}>
      <Image
        src={OnboardingImageFive.src}
        alt=""
        style={{
          width: '100vw',
          height: '100%',
          maxWidth: '400px',
          marginBottom: '32px',
        }}
        width={0}
        height={0}
      />
      <Stack
        spacing={2}
        sx={{
          paddingBottom: '64px',
          whiteSpace: 'pre-line',
        }}
      >
        <Box
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            color: '#343D54',
          }}
        >
          {regexFormatter(t('main.title_4')).map((e, idx) => (
            <Box key={idx} component="span">
              {e}
            </Box>
          ))}
        </Box>
        <Box sx={{ color: '#666b85', fontWeight: '400', lineHeight: '24px' }}>
          {t('main.subtitle_4')}
        </Box>
      </Stack>
    </Stack>
  );
};

const Index: FC = () => {
  const { data: uuid } = useSWRImmutable<string>('uuid', uuidFetcher);
  const [step, setStep] = useState<ComponentName[]>([]);
  const router = useRouter();
  const [iosCheck, setIosCheck] = useState(true);
  const [androidCheck, setAndroidCheck] = useState(true);
  const { i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (typeof window === 'undefined') return;
    setIosCheck(isIOS);
    setAndroidCheck(isAndroid);
  }, []);

  // useEffect(() => {
  //   i18n.init({ resources: {} });
  //   // need better way
  //   i18n.addResourceBundle('en', 'common', ENjson);
  //   i18n.addResourceBundle('ko', 'common', KOjson);
  //   i18n.addResourceBundle('vi', 'common', VIjson);
  //   i18n.addResourceBundle('ja', 'common', JAjson);
  // }, [i18n, language]);

  useEffect(() => {
    if (router.query && router.query.url) {
      const url = router.query.url as string;
      const decoded = decodeURIComponent(url);
      // 'intent://details/product/221#Intent;scheme=hitchicar;package=com.hitchhicar;end'
      if (androidCheck) {
        fLogEvent('hitchicar_web_applink_android', {
          url: decoded,
        });
        window.location.replace(
          `intent://${decoded}#Intent;scheme=hitchicar;package=${getPlaystoreID()};end`
        );
      }
      if (iosCheck) {
        fLogEvent('hitchicar_web_applink_ios', {
          url: decoded,
        });
        window.location.replace(`hitchicar://${decoded}`);
        setTimeout(() => {
          window.location.replace('https://apps.apple.com/app/id6450913712');
        }, 5000);
      }
    }
  }, [androidCheck, iosCheck, router.query]);

  useEffect(() => {
    if (step.length === 0 || !uuid) return;
    fLogEvent('hitchicar_web_onboarding', {
      steps: step.join(','),
      uuid: uuid,
    });
  }, [step, uuid]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'center',
          position: 'fixed',
          bottom: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(255, 255, 255, 0.70)',
          padding: '32px',
          width: '100%',
        }}
      >
        <Image
          onClick={() => {
            fLogEvent('hitchicar_web_onboarding_google', {
              uuid: uuid,
            });
            window.open(
              'https://play.google.com/store/apps/details?id=com.hitchhicar',
              '_blank'
            );
          }}
          src={language === 'ko' ? Google : GoogleEN}
          alt="google"
          width={0}
          height={0}
          style={{
            height: '100%',
            width: 'min(192px, 45vw)',
            cursor: 'pointer',
          }}
        />
        <Image
          onClick={() => {
            fLogEvent('hitchicar_web_onboarding_ios', {
              uuid: uuid,
            });
            window.open('https://apps.apple.com/app/id6450913712', '_blank');
          }}
          src={language === 'ko' ? AppStore : AppStoreEN}
          alt="apple"
          width={0}
          height={0}
          style={{
            height: '100%',
            width: 'min(192px, 45vw)',
            cursor: 'pointer',
          }}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 100,
          backgroundColor: '#EFF2F4',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '512px',
            backgroundColor: '#EFF2F4',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: '56px',
              marginBottom: '110px',
            }}
          >
            <FirstBlock step={step} setStep={setStep} />
            <SecondBlock step={step} setStep={setStep} />
            <ThirdBlock step={step} setStep={setStep} />
            <FourthBlock step={step} setStep={setStep} />
            <LastBlock step={step} setStep={setStep} />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default Index;

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], i18nConfig)),
    },
  };
}
