import { Box } from '@mui/material';

export const nFormatter = (num: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: '천' },
    { value: 1e4, symbol: '만' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol
    : '0';
};

const hashTagRegex =
  /[^ㄱ-ㅎ가-힣a-zA-Z0-9_\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55]+/;

export interface Block {
  content: string;
  type: 'Plain' | 'HashTag';
}

export const SplitHashtag = (content: string) => {
  const chunks = content.split('#');
  const blocks: Block[] = [];
  for (let i = 0; i < chunks.length; i++) {
    if (i > 0) {
      const words = chunks[i].split(hashTagRegex);
      const shifted = words.shift();
      blocks.push({
        content: `#${shifted}`,
        type: shifted!.length > 0 ? 'HashTag' : 'Plain',
      });
      if (chunks[i].length > shifted!.length) {
        blocks.push({
          content: `${chunks[i].substring(shifted!.length)}`,
          type: 'Plain',
        });
      }
      continue;
    }
    blocks.push({ content: chunks[i], type: 'Plain' });
  }
  return blocks;
};

export const regexFormatter = (content: string): JSX.Element[] => {
  const finded = content.split('%%');
  const element: JSX.Element[] = [];
  for (let i = 0; i < finded.length; i++) {
    if (i % 2 === 0) {
      element.push(<Box component="span">{finded[i]}</Box>);
    } else {
      element.push(
        <Box component={'span'} sx={{ color: '#2E90FA' }}>
          {finded[i]}
        </Box>
      );
    }
  }
  return element;
};
