import { v4 } from 'uuid';

export const uuidFetcher = () => {
  const uid = localStorage.getItem('uuid');
  if (!uid) {
    const newUid = v4();
    localStorage.setItem('uuid', newUid);
    return newUid;
  }
  return uid;
};
