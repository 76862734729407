import {
  Analytics,
  CustomEventName,
  getAnalytics,
  logEvent,
} from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getEnv, getFirebaseConfig } from '@/common/config';

let fApp: FirebaseApp | undefined = undefined;
let fAnalytics: Analytics | undefined = undefined;

function setupFirebase() {
  if (getFirebaseConfig() === undefined) {
    return;
  }
  if (typeof window === 'undefined') {
    return;
  }
  const env = getEnv();
  if (env && env !== 'prod') {
    return;
  }
  if (fApp === undefined) {
    console.debug('Firebase initialized');
    fApp = initializeApp(getFirebaseConfig());
  }
  if (fAnalytics === undefined) {
    console.debug('Analytics initialized');
    fAnalytics = getAnalytics(fApp);
  }
}

setupFirebase();

export function fLogEvent<T extends string>(
  eventName: CustomEventName<T>,
  eventParams?: { [key: string]: string | number }
): void {
  if (!fAnalytics) {
    return;
  }
  logEvent(fAnalytics, eventName, eventParams);
}
